(function () {
    "use strict";

    function getScript(url, success) {
        var script = document.createElement('script');
        script.src = url;

        var head = document.getElementsByTagName('head')[0];
        var done = false;

        script.onload = script.onreadystatechange = function () {
            if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                done = true;

                success();

                script.onload = script.onreadystatechange = null;
                head.removeChild(script);
            }
        };

        head.appendChild(script);
    }

    function loadJQuery(callback) {
        if (typeof jQuery === 'undefined') {
            getScript('https://code.jquery.com/jquery-1.12.3.min.js', function () {
                if (typeof jQuery !== 'undefined') {
                    var j = jQuery;
                    jQuery.noConflict();
                    callback(j);
                }
            });
        } else {
            callback(jQuery);
        }
    }

    loadJQuery(function ($) {
        $(function () {
            var widgets = $(".mcd-search-widget");
            var categoryGroupId = 0;
            var categoryId = 0;
            var locationId = 0;
            var councilId = widgets.data("council-id") || 0;
            var hasHref = widgets.is("[data-href]");
            var websitePath = widgets.data("href") || "https://www.mycommunitydirectory.com.au";
            var catField = $("input.autocomplete-category", widgets);
            var locField = $("input.autocomplete-location", widgets);
            var distField = $(".distance", widgets);
            var searchParameters = {};
            var apiPath = "https://api.mycommunitycentral.com";
            var apiVersion = 1;

            if (councilId) {
                searchParameters.CouncilRegion = councilId;
            }

            function updateSearchUrl() {
                var searchUrl = websitePath + "/Search/Directory?what=" + encodeURIComponent(catField.val()) + "&where=" + encodeURIComponent(locField.val()) + "&category=" + categoryId + "&categorygroup=" + categoryGroupId + "&location=" + locationId + "&radius=" + (distField.val() || "50") + "&council=" + councilId;
                $(".search-button").attr("href", searchUrl);
            }

            function showSpinner(item) {
                $(item).addClass("spinner");
            }

            function removeSpinner(item) {
                $(item).removeClass("spinner");
            }

            function setupDropDowns() {
                getScript(websitePath + "/_static/vendor/typeahead/typeahead.bundle.min.js", function () {
                    var q = '';

                    var categories = new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('Name'),
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        limit: 10,
                        remote: {
                            url: websitePath + '/api/search/services-basic/%QUERY',
                            ajax: {
                                beforeSend: function () { showSpinner(catField); },
                                complete: function () { removeSpinner(catField); }
                            }
                        }
                    });

                    var locations = new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('Name'),
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        limit: 10,
                        remote: {
                            url: apiPath + "/api/v" + apiVersion + "/system/localities/search",
                            replace: function (url, query) {
                                q = query;
                                // Change the url target url so it triggers
                                url = url + '#' + encodeURIComponent(q);
                                return url;
                            },
                            ajax: {
                                beforeSend: function (ev, settings) {
                                    settings.data = JSON.stringify($.extend({}, searchParameters, { SearchTerm: q }));
                                },
                                complete: function () { removeSpinner(locField); },
                                dataType: "json",
                                contentType: "application/json; charset=utf-8",
                                type: "POST"
                            },
                            filter: function (results) {
                                if (results.success) {
                                    return $.map(results.data, function (result) {
                                        return {
                                            Name: result.Name,
                                            LocalityID: result.LocalityID
                                        };
                                    });
                                }

                                return null;
                            }
                        }
                    });

                    categories.initialize();
                    locations.initialize();

                    catField.typeahead({
                        hint: true,
                        minLength: 2,
                        highlight: true
                    }, {
                            name: 'categories',
                            displayKey: 'Name',
                            source: categories.ttAdapter(),
                            templates: {
                                suggestion: function (d) {
                                    return "<p class='needsclick'>" + d.Name + "</p>";
                                }
                            }
                        });
                    catField.on('typeahead:opened', function () {
                        categoryGroupId = 0;
                        categoryId = 0;
                        updateSearchUrl();
                    });
                    catField.on('typeahead:selected', function (obj, datum, name) {
                        categoryGroupId = datum.CategoryGroupID;
                        categoryId = datum.CategoryID;
                        updateSearchUrl();
                    });
                    catField.on('typeahead:autocompleted', function (obj, datum, name) {
                        categoryGroupId = datum.CategoryGroupID;
                        categoryId = datum.CategoryID;
                        updateSearchUrl();
                    });
                    catField.on("input", function () {
                        updateSearchUrl();
                    });

                    // Locations
                    locField.typeahead({
                        hint: true,
                        minLength: 2,
                        highlight: true
                    }, {
                            name: 'locations',
                            displayKey: 'Name',
                            source: locations.ttAdapter(),
                            templates: {
                                suggestion: function (d) {
                                    return "<p class='needsclick'>" + d.Name + "</p>";
                                }
                            }
                        });
                    locField.on('typeahead:opened', function () {
                        locationId = 0;
                        updateSearchUrl();
                    });
                    locField.on('typeahead:selected', function (obj, datum, name) {
                        locationId = datum.LocalityID;
                        updateSearchUrl();
                    });
                    locField.on('typeahead:autocompleted', function (obj, datum, name) {
                        locationId = datum.LocalityID;
                        updateSearchUrl();
                    });
                    locField.on("input", function () {
                        updateSearchUrl();
                    });

                    distField.on("change", function () {
                        updateSearchUrl();
                    });

                    // Already displaying provided by image...
                    if (!$(".mcd-search-widget img[src$='data_provided_by_mcd_300x40.png']").length) {
                        var $providedBy = $("<div>").addClass("provided-by")
                            .append($("<div>").html("DATA PROUDLY PROVIDED BY"))
                            .append($("<div>"));
                        if ($(".mcd-search-widget .container").length) {
                            $(".mcd-search-widget .container").append($providedBy);
                        }
                        else {
                            $(".mcd-search-widget").append($providedBy);
                        }
                    }

                    updateSearchUrl();
                });
            }

            var jqxhr = $.get(websitePath + "/api/api-info")
                .done(function (data) {
                    apiPath = data.ApiPath;
                    apiVersion = data.MaxSupportedApiVersion;
                });

            jqxhr.always(function () {
                if (widgets.data("region-type") && widgets.data("region-id")) {
                    $.post(apiPath + "/api/v" + apiVersion + "/system/localities/parameters",
                        { Type: widgets.data("region-type"), Id: widgets.data("region-id") })
                        .done(function (data) {
                            searchParameters = data;
                            if (!hasHref && (searchParameters.Href || "").length) {
                                websitePath = searchParameters.Href;
                            }
                        })
                        .always(function () {
                            setupDropDowns();
                        });
                }
                else {
                    setupDropDowns();
                }
            });
        })
    });
})();
